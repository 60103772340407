<template>
    <div class="currency-input-component p-inputgroup">
        <span class="p-inputgroup-addon">{{ currencyPrefix }}</span>
        <span v-if="disabled" class="input-value p-inputtext p-component p-filled p-disabled">
            {{ currencyValue }}
        </span>
        <InputText
            v-else
            class="input-value"
            v-model="currencyValue"
            :style="{ color: amount < 0 ? negativeColor : positiveColor }"
            @keyup="setCursorEnd"
            @click="setCursorEnd"
        />
    </div>
</template>

<script>
import InputText from "primevue/inputtext";

export default {
    name: "CurrencyInput",
    components: { InputText },
    props: {
        value: { type: Number, default: 0 },
        locale: { type: String, default: 'pt-BR' },
        currency: { type: String, default: 'BRL' },
        defaultPrefix: { type: String, default: 'R$' },
        positiveColor: { type: String, default: '#4372ae' },
        negativeColor: { type: String, default: '#ba0000' },
        disabled: { type: Boolean, default: false },
    },
    data() {
        return { amount: this.value };
    },
    watch: {
        value(val) { this.amount = val; },
    },
    computed: {
        currencyFormatter() {
            return new Intl.NumberFormat(this.locale, { style: 'currency', currency: this.currency });
        },
        currencyPrefix() {
            const parts = this.currencyFormatter.formatToParts(1);
            return parts.find(part => part.type === 'currency')?.value || this.defaultPrefix;
        },
        currencyValue: {
            get() { return this.formatCurrency(false); },
            set(value) {
                const isNegative = value.includes('-') && value[value.length -1] !== '+';
                const sanitizedValue = value.replace(/\D/g, '').padStart(3, '0').replace(/(\d+)(\d{2})$/, '$1.$2');
                this.amount = parseFloat(sanitizedValue) * (isNegative ? -1 : 1);
                this.updateValue();
            },
        },
    },
    methods: {
        setCursorEnd(event) {
            const pos = event.target.value.length;
            event.target.setSelectionRange(pos, pos);
        },
        formatCurrency(withPrefix=true) {
            this.amount = this.amount.toString();

            let result = this.currencyFormatter.format(this.amount);
            if (withPrefix) return result;
            return result.replace(this.currencyPrefix, "").trim();
        },
        updateValue() {
            this.$emit('input', this.amount);
            this.$emit('change', this.amount);
        },
    },
};
</script>

<style lang="scss">
.currency-input-component {
    .input-value {
        flex: 1;
        color: #4372ae;
        text-align: right;
        font-weight: 700;
    }
}
</style>
